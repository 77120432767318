import React, { useContext } from "react"
import { ContentContainer, ContentSection } from "./style"
import { PageWrapper } from "components/PageWrapper"
import { UserContext } from "context/UserContext.js"

const TalkingFeedbackScreen = ({ data }) => {
  const { user } = useContext(UserContext)
  const responses = user.responses[user.responses.length - 1].results

  const matches = responses.map(response => {
    return data.sections.find(section => section.title === response)
  })

  console.log(matches)

  return (
    <PageWrapper>
      {matches.length ? (
        <ContentContainer>
          {matches.map((section, index) => {
            return (
              <ContentSection key={index}>
                <h3>{section.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: section.body }} />
              </ContentSection>
            )
          })}

          <ContentSection>
            <p>
              <b>Important:</b> All Tutor and Safer Community Program contact
              details are available in the Action Plan section of this website.
            </p>
          </ContentSection>
        </ContentContainer>
      ) : (
        <div></div>
      )}
    </PageWrapper>
  )
}

export default TalkingFeedbackScreen
